import { useDispatch, useSelector } from "react-redux";
import type { RootState } from "../../../store/reducers/rootReducer";
import { useEffect, useState } from "react";
import { fetchGetJsonData, fetchPutJsonData } from "../../../services/services";
import { Link, useParams } from "react-router-dom";
import { FullHeightSpinner } from "../../shared/FullHeightSpinner/FullHeightSpinner";
import { AudienceProjectHeaders, type AudienceStatusType, type Batch } from "../../../interfaces/auddineceAccessInterfaces/audiencesTypes";
import { DropdownButton } from "../../shared/DropdownButton/DropdownButton";
import { statuses, updateStatusInputClasses } from "../../Audiences/Audiences";
import { AudienceBatchesActions } from "./AudienceBatchesActions";
import { FullHeightOverlaySpinner } from "../../shared/FullHeightOverlaySpinner/FullHeightOverlaySpinner";

export const Batches = () => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { customerId } = useSelector((state: RootState) => state.audienceAdminReducer);
  const params: { projectId: string, audienceId: string, sampleId: string, batchId: string } = useParams();
  const { projectId, sampleId, audienceId } = params;
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState(true);
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [isOverlayLoading, setIsOverlayLoading] = useState(false);
  const [batches, setBatches] = useState<Batch[]>([]);

  useEffect(() => {
    if (projectId && sampleId && audienceId) {
      fetchGetJsonData<Batch[]>({ 
        url: `au/a/projects/${projectId}/samples/${sampleId}/audiences/${audienceId}/batches`, 
        token: token, 
        customerId: customerId,
        baseUrl: "audience" 
      })
      .then((res) => {
        setIsLoading(false);
        setBatches(res);
      }).catch((error) => {
        console.log(error);

        setIsLoading(false);
      })
    } else {
      setIsLoading(false);
    }
  }, [projectId, sampleId, audienceId, token]);

  const updateStatus = (status: AudienceStatusType, batchId: string, etag: string | null) => {
    fetchPutJsonData<{ etag: string }>({ 
      url: `au/a/projects/${projectId}/samples/${sampleId}/audiences/${audienceId}/batches/${batchId}/status`, 
      token: token, 
      customerId: customerId,
      body: { status: status, etag: etag }, 
      baseUrl: "audience" 
    })
    .then((res) => {
      setIsStatusLoading(false)
      const updatedBatches = batches.map((item) => item.id === batchId ? { ...item, status: status, etag: res.etag } : item)
      setBatches(updatedBatches);
      dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Status updated successfully' } })
    }).catch((error) => {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } });
    })
  }

  return (
    <div className='d-flex justify-content-center flex-column w-100 bg-white gap-lg projects p-0' style={{ height: "65svh" }}>
      <section className="position-relative h-100 pb-3">
        <article className=' bg-white'>
          <div className='project-header mx-0 row py-2 text-primary strong border-bottom'>
            {AudienceProjectHeaders.map((tab) => (
              <span
                key={tab}
                className={`${tab === "Name" ? 'col-2' : 'col'} text-nowrap`}>
                {tab}
              </span>
            ))}
          </div>
        </article>

        {isLoading && <FullHeightSpinner />}
        {isOverlayLoading && (<FullHeightOverlaySpinner />)}
        {
          batches?.map((batch) =>
            <div key={batch.id} className='m-0 row project-sub-row project-second-sub-row bg-white'>
              <div className="col-2 d-flex flex-column w-min-300">
                <span className="text-nowrap text-truncate mr-2 mb-1">
                  <Link
                    className='strong'
                    to={`/projects/${projectId}/samples/${sampleId}/audience/${audienceId}/batch/${batch.id}`}
                    title={batch.displayName}
                  >
                    {batch.displayName}
                  </Link>
                </span>

                <span className='d-flex flex-row'>
                  <DropdownButton
                    items={statuses}
                    className={`btn btn-shadow small h-24 btn-icon icon-r ${updateStatusInputClasses[batch.status]}`}
                    text={batch.status}
                    parentClass='status-dropdown audience-batch'
                    disabled={isStatusLoading}
                    onItemClick={(e) => {
                      setIsStatusLoading(true);
                      updateStatus(e.item.text, batch.id, batch.etag)
                    }}
                  />
                </span>
              </div>

              <p className="d-flex col m-0 text-nowrap align-items-center text-truncate w-min-250">{batch.projectManagerEmail}</p>

              <p className="d-flex col text-nowrap align-items-center text-truncate m-0">
                {batch.completes}
              </p>

              <p className="d-flex col m-0 text-nowrap align-items-center text-truncate">
                {batch.currencySymbol}{batch.cpi?.toFixed(2)}
              </p>

              <p className="d-flex col m-0 text-nowrap align-items-center text-truncate">
                {Number(batch.incidenceRateStats?.actual) ? `${Math.round(batch.incidenceRateStats?.actual)}%` : <span className='text-empty'>-</span>}
                {
                  Number(batch.incidenceRateStats?.difference) ?
                    <span className='text-muted ml-1'>{`(${Math.round(batch.incidenceRateStats?.difference)}%)`} </span>
                    :
                    null
                }
              </p>

              <p className="d-flex col m-0 text-nowrap align-items-center text-truncate">
                {Number(batch.incidenceRateStats?.actual) ? `${Math.round(batch.incidenceRateStats?.actual)}%` : <span className='text-empty'>-</span>}
                {
                  Number(batch.incidenceRateStats?.difference) ?
                    <span className='text-muted ml-1'>{`(${Math.round(batch.incidenceRateStats?.difference)}%)`} </span>
                    :
                    null
                }
              </p>

              <div className='d-flex col align-items-center justify-content-end'>
                <AudienceBatchesActions batch={batch} batches={batches} setBatches={setBatches} setIsOverlayLoading={setIsOverlayLoading} />
              </div>
            </div>
          )
        }
      </section>

    </div>
  )
}
