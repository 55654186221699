import type { PreScreenerSurveyQuestion } from "./audiencesTypes";
import type { BatchOverviewType } from "./batchOverviewTypes";

export const AudienceOverviewTabs = ["Details", "Batches", "Reconcile", "Audit log", "Links", "Walr Verify"] as const;
export type AudienceOverviewTabsType = (typeof AudienceOverviewTabs)[number];

export const AudienceSurveyTypes = ["AdHoc", "Tracker", "Recontact"] as const;
export type AudienceSurveyTypesType = (typeof AudienceSurveyTypes)[number];


export type UniqueUrlsModalType = {
  show: boolean
  type?: "liveUrl" | "testUrl";
  isUniqueUrlEnabled: boolean
}

export type AudienceToExclude = {
  id: string
  displayName: string
}

type Exclusion = {
  respondentStatuses: string[];
  exclusionDurationDays: number;
  audiences: AudienceToExclude[];
}

type BuyerDetails = {
  price: number;
  currency: string;
  priceSuggestedUtc: string;
  priceAcceptedUtc: string;
  priceKey: string;
  feasibilityStatus: number;
  notes: string;
}

export type Market = {
  country: string;
  countryName: string;
  language: string;
  languageName: string;
  locale: string;
}

export type AudienceOverviewType = {
  audienceAction?: "new" | "copy" | "edit";
  id: string;
  projectNumber: string;
  customerId: string;
  displayName: string;
  surveyType: "AdHoc" | "Tracker" | "Recontact";
  surveyTestUrl: string;
  surveyLiveUrl: string;
  shortKey: string;
  isOpen: boolean;
  pii: boolean;
  allowReentry: boolean;
  redirectCaptureParameter: string | null;
  exclusion: Exclusion | null;
  reconciliationId: string | null;
  useUniqueUrls: boolean;
  uniqueUrlsWidParam: string | null;
  batchIds: string[];
  createdUtc: string;
  createdByEmail: string;
  buyerDetails: BuyerDetails | null;
  market: Market;
  etag: string | null;
  sampleId: string;
  projectId: string;
  originalIdRecontactVariable: string | null;
  walrIdRecontactVariable: string | null;
  sampleInfo: { displayName: string } | null;
  prescreening: PreScreeningAudienceType | null;
}

export type AudienceCopyType = AudienceOverviewType & { batchResponse: BatchOverviewType };

export type CreateAudience = {
  projectId: string;
  audienceId: string;
  audienceEtag: string;
  batchId: string;
  batchEtag: string;
}

type AuditLogItem = {
  name: string;
  type: string;
  action: string;
}

type AuditLogDiffs = {
  path: string;
  oldValue: string;
  newValue: string;
}

export type AuditLogType = {
  id: string;
  name: string;
  date: string;
  item: AuditLogItem;
  diffs: AuditLogDiffs[];
}

export type PreScreeningAudienceType = {
  surveyId?: string;
  projectId?: string;
  surveyUrl?: string;
  questionLibrary?: QuestionLibrary;
  isPublished: boolean;
  selectedQuestions?: PreScreenerSurveyQuestion[];
}

type QuestionLibrary = {
  projectId?: string;
  id?: string;
  name?: string;
}

export type PreScreenerPublishSurveyType = {
  isValid: boolean;
  isPublished: boolean;
  indexUpdated: boolean;
  updatedIndex: boolean | null;
}

export type PreScreenerSurveyLinkType = {
  url: string;
  projectId: string;
  surveyId: string;
  disabled: boolean;
  interviews: number;
  tags: string[];
  activePerHour: number | null;
  preValidate: boolean;
  preValidationUrl: string | null;
  name: string;
  description: string;
  readReceipts: string | null;
  id: string;
  type: string;
  createdUtc: string;
  createdByName: string;
  isDeleted: boolean;
}