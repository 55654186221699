import { type FormEvent, useState } from "react";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { useDispatch, useSelector } from "react-redux";

import type { RootState } from "../../../store/reducers/rootReducer";
import { fetchPostFile } from "../../../services/services";

type Props = {
  setRecontactsModal: (open: boolean) => void;
  setRecontactsFileId: (id: string) => void;
}

export const UploadRecontactsModal = ({ setRecontactsModal, setRecontactsFileId }: Props) => {
  const { projectId, sampleId } = useSelector((state: RootState) => state.audienceDataReducer);
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { customerId } = useSelector((state: RootState) => state.audienceAdminReducer);
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.stopPropagation();
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    setIsLoading(true);

    fetchPostFile(
      `au/a/projects/${projectId}/samples/${sampleId}/audiences/uploadrecontactfile`, 
      token, 
      formData, 
      false, 
      true,
      customerId
    )
    .then((res: TODO) => {
      if (res.ok === false) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: 'Something went wrong. Please try again.' } });
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setRecontactsFileId(res.fileId);
        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: res.message } });
        setRecontactsModal(false);
      }
    })
  }

  return (
    <Dialog width={"600px"} title={"Recontacts"} onClose={() => setRecontactsModal(false)}>
      <form onSubmit={onFormSubmit} encType="multipart/form-data" className="d-flex flex-column gap-md">
        <div className="d-flex gap-md p-4">
          <label htmlFor="file" className='btn btn-primary'>Browse files</label>
          <input id="file" name="file" type="file" accept=".txt, .xlsx, .csv" onChange={(e) => setFileName(e.target.files ? e.target.files[0].name : "File")} hidden />
          <input name="fileId" placeholder="Select file" value={fileName} readOnly className="form-control h-32 pe-none" />
        </div>

        <DialogActionsBar>
          <button type='submit' className="btn btn-primary gap-md" disabled={isLoading}>
            Upload recontacts
            {
              isLoading &&
              <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true" />
            }
          </button>
        </DialogActionsBar>
      </form>
    </Dialog >
  )
}
