import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ReconcileModal } from '../shared/ReconcileModal';
import { ReconcileTable } from './ReconcileTable/ReconcileTable';
import type { RootState } from '../../../store/reducers/rootReducer';
import { SupplierAccordion } from './SupplierTable/SupplierAccordion';
import type { ReconciliationData, ReconciliationDataSlim } from '../../../interfaces/reconciliationsInterfaces/gateKeeperInterfaces';
import { fetchGetJson } from '../../../services/services';

export const AudienceReconcile = () => {
  const [showNewReconcileModal, setShowNewReconcileModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reconciliationData, setReconciliationData] = useState<ReconciliationData>();
  const [reconciliationDataSlim, setReconciliationDataSlim] = useState<ReconciliationDataSlim>();
  const [didMount, setDidMount] = useState(false);

  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { customerId } = useSelector((state: RootState) => state.audienceAdminReducer);
  const params: { name: string } = useParams();
  const dispatch = useDispatch()

  const handleNewReconcile = () => {
    setShowNewReconcileModal(true)
  }

  const handleExport = () => {
    setIsLoading(true)
    fetchGetJson(`au/r/${reconciliationData?.id}/export`, token, false, true, customerId)
      .then(() => {
        setDidMount(false)
        if (reconciliationData) {
          if (reconciliationData.status === "Reconciled") {
            dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Reconciled IDs successfully exported' } })
          } else {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "No reconciled IDs found" } });
          }
        } else {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "An error occured while exporting" } })
        }
        setIsLoading(false)
      }).catch((res: TODO) => {
        setIsLoading(false)
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: `Export file: ${res.detail}` || res.message } });
      })
  }

  useEffect(() => {
    if (!didMount) {
      setDidMount(true)
      setIsLoading(true)
      fetchGetJson(`au/r/projects/${params.name}`, token, false, true, customerId)
        .then(async (res: TODO) => {
          if (res) {
            setIsLoading(false)
            setReconciliationData(res)
          } else {
            setIsLoading(false)
          }
        }).catch(async (res: TODO) => {
          setIsLoading(false)
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: res.status !== 200 ? `Error get Reconciliations: ${res.detail}` : res.message } });
        })
    }
  }, [didMount, token, params.name, dispatch, customerId])

  return (
    <div className='board-container reconcile-container d-flex justify-content-center flex-column w-100' >
      {
        showNewReconcileModal &&
        <ReconcileModal setReconciliationDataSlim={setReconciliationDataSlim} setShowNewReconcileModal={setShowNewReconcileModal} type='audienceOverview' />
      }

      <article className='nav-board d-flex flex-column bg-white answer-layout p-5 gap-lg'>
        <ReconcileTable
          reconciliationDataSlim={reconciliationDataSlim}
          reconciliationData={reconciliationData}
          isLoading={isLoading}
          handleNewReconcile={handleNewReconcile}
          handleExport={handleExport}
        />

        {
          reconciliationData?.summary &&
          <SupplierAccordion
            reconciliationSummary={reconciliationData?.summary}
            isLoading={isLoading}
          />
        }
      </article >
    </div >
  )
}
