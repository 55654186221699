import { type FormEvent, useState, Fragment } from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useDispatch, useSelector } from 'react-redux';

import { type ValidationError, handleZodValidation } from '../../shared/Zod/handleZodValidation';
import type { RootState } from '../../../store/reducers/rootReducer';
import { Icon } from '../../shared/Icon/Icon';
import { newSupplierSchema } from '../../shared/Zod/ZodSchemas/newSupplierSchema';
import { fetchPostJsonData } from '../../../services/services';

type Props = {
  onHide: (type: string) => void,
}

const formFields = [
  { name: 'name', label: 'Supplier name', placeholder: 'Enter supplier name', showInfo: true },
  { name: 'completeUrl', label: "Complete redirect", placeholder: 'http://', showInfo: false },
  { name: 'quotaOutUrl', label: "Over quota redirect", placeholder: 'http://', showInfo: false },
  { name: 'qualityFailUrl', label: "Quality termination redirect", placeholder: 'http://', showInfo: false },
  { name: 'screenOutUrl', label: "Screen out redirect", placeholder: 'http://', showInfo: false },
];

export default function NewSupplierModal({ onHide }: Props) {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { customerId } = useSelector((state: RootState) => state.audienceAdminReducer);

  const dispatch = useDispatch();

  const [errors, setErrors] = useState<ValidationError<typeof newSupplierSchema>>();
  const [newSupplierSuccess, setNewSupplierSuccess] = useState(false);
  const [platformId, setPlatformId] = useState<number | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const saveSupplier = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formData = Object.fromEntries(new FormData(event.currentTarget));

    handleZodValidation({
      onError: setErrors,
      data: formData,
      onSuccess: () => {
        setErrors({});
        setIsLoading(true);
        fetchPostJsonData<{ platformId: number }>({ url: "au/a/suppliers", token: token, body: formData, baseUrl: "audience", customerId: customerId })
          .then((res) => {
            setPlatformId(res.platformId)
            setNewSupplierSuccess(true);
            setIsLoading(false);
          })
          .catch((error) => {
            dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } })
            setIsLoading(false);
          })
      },
      schema: newSupplierSchema,
    });
  }

  return (
    <Dialog width={"600px"} className='supplier-new-modal' title={`${newSupplierSuccess ? "Supplier created " : "Create new supplier"}`} onClose={() => onHide("Suppliers New")}>
      {newSupplierSuccess
        ?
        <div className='submitted-content'>
          <div className='d-flex align-items-center row p-2 m-4 submit-info border rounded gap-md'>
            <i style={{ width: 24, height: 24 }} className="fas fa-check bg-success" />
            <p className='m-0' >Your new suppliers has successfully been created.
              <br />
              The platform ID is <strong>{platformId}</strong>
            </p>
          </div>
          <DialogActionsBar>
            <div className='p-2'>
              <button
                type='submit'
                className="k-button rounded bg-secondary text-primary medium mr-3"
                onClick={() => onHide("Suppliers New")}
              >
                Close
              </button>
            </div>
          </DialogActionsBar>
        </div>
        :
        <form onSubmit={(e) => saveSupplier(e)} >
          <div className='d-flex flex-column gap-md p-2'>
            {formFields.map((formField) => {
              return (
                <Fragment key={formField.name}>
                  <div className="d-flex flex-column gap-sm">
                    <label className='p-0 mb-0 strong text-primary' htmlFor='name'>{formField.label}</label>
                    <input className='form-control' type="text" id="name" name={formField.name} placeholder={formField.placeholder} />
                    {errors &&
                      <div className="text-danger">
                        <small>{errors[formField.name as unknown as keyof typeof errors]}</small>
                      </div>}
                  </div>

                  {formField.showInfo && (
                    <p className='text-primary bg-secondary p-2 m-0 medium d-flex align-items-center gap-sm rounded'>
                      <Icon type="info-icon" fill='primary' />
                      Please use [%RID%] to indicate where the Respondent ID should be populated.
                    </p>
                  )}
                </Fragment>
              )
            })}
          </div>

          <DialogActionsBar>
            <button type='button' className="btn btn-secondary" onClick={() => onHide("Suppliers New")}>Cancel</button>
            <button type='submit' className="btn btn-primary" disabled={isLoading}>
              {isLoading && <div className="spinner-border spinner-border-sm mr-1" />}
              Save supplier
            </button>
          </DialogActionsBar>
        </form>
      }
    </Dialog >
  )
}