import { Fragment, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { AudiencesHeader } from './AudiencesHeader';
import { AudiencesNoProjects } from './AudiencesNoProjects';
import type { RootState } from '../../store/reducers/rootReducer';
import { ErrorMessage } from '../shared/ErrorMessage/ErrorMessage';
import { DropdownButton } from '../shared/DropdownButton/DropdownButton';
import { FullHeightSpinner } from '../shared/FullHeightSpinner/FullHeightSpinner';
import { AudienceActionsDropdown } from './AudienceActionsButton/AudienceActionsButton';
import { editProjects, setAudiences } from '../../store/reducers/audiencesReducer/audiencesReducer';
import { FullHeightOverlaySpinner } from '../shared/FullHeightOverlaySpinner/FullHeightOverlaySpinner';
import { type AudiencesType, AudienceStatus, type AudienceStatusType, type Markets, type AudienceUpdateStatusType, AudienceProjectHeaders, type Sample, type AudiencesData } from '../../interfaces/auddineceAccessInterfaces/audiencesTypes';
import { fetchGetJsonData, fetchPutJsonData } from '../../services/services';
import { editAudiencesNavigation } from '../../store/reducers/audiencesNavigationReducer/audiencesNavigationReducer';
import { parseJwt } from '../shared/helpers/decodeJWT/decodeJWT';

export const statuses = AudienceStatus.map((status: AudienceStatusType) => ({ text: status }))
export const updateStatusInputClasses: Record<AudienceStatusType, AudienceUpdateStatusType> = {
  Pending: 'pending',
  Live: 'live',
  Closed: 'default',
  Cancelled: 'default',
  Reconciled: 'default',
  Archived: 'default',
  Draft: 'default'
}

export const Audiences = () => {
  document.title = "Audiences – Walr";
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const audiencesData = useSelector((state: RootState) => state.audiencesDataReducer);
  const { customerId } = useSelector((state: RootState) => state.audienceAdminReducer);
  const audiencesNavigationData = useSelector((state: RootState) => state.audiencesNavigationReducer);
  const dispatch = useDispatch();

  const [isOverlayLoading, setIsOverlayLoading] = useState(false);
  const [errorModal, setErrorModal] = useState({ show: false, message: '' })
  const [isLoading, setIsLoading] = useState(true);
  const [markets, setMarkets] = useState<Markets[]>([]);
  const [expandSample, setExpandSample] = useState<string | undefined>(audiencesNavigationData?.sampleId);
  const [expandAudience, setExpandAudience] = useState<string | undefined>(audiencesNavigationData?.audienceId);
  const [selectedMarket, setSelectedMarket] = useState<Markets>({ name: '', isoCode: '' })
  const [searchTerm, setSearchTerm] = useState("");
  const [isStatusLoading, setIsStatusLoading] = useState(false)

  useEffect(() => {
    fetchGetJsonData<Markets[]>({ 
      url: "au/a/markets", 
      token: token, 
      baseUrl: "audience",
      customerId
    }).then((res) => {
      const markets = res.map((market) => ({ name: market.name, isoCode: market.isoCode }));
      setMarkets(markets);
    }).catch((error) => {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } })
    });

    if (audiencesNavigationData.sampleId) {
      setIsOverlayLoading(true);
    }

    const overviewUrl = audiencesNavigationData?.sampleId ? `au/a/overview?sampleId=${audiencesNavigationData?.sampleId}` : "au/a/overview";

    fetchGetJsonData<AudiencesData>({ 
      url: overviewUrl, 
      token: token, 
      baseUrl: "audience",
      customerId
    }).then((res) => {
      setIsLoading(false)
      if (audiencesNavigationData?.sampleId) {
        const findSampleName = res.samples.find((item) => item.id === audiencesNavigationData?.sampleId)?.name;
        setSearchTerm(findSampleName ? findSampleName : "");
      }
      dispatch(setAudiences({ ...res }))
      setIsOverlayLoading(false);
    }).catch(() => {
      setIsLoading(false);
    })
  }, [dispatch, token, customerId, audiencesNavigationData?.sampleId]);

  const fetchAudiencesData = (searchTermValue: string, selectedMarketValue: string, continuationToken?: string | null) => {
    setIsLoading(true);
    let fetchUrl = "au/a/overview";
    if (searchTermValue && selectedMarketValue) {
      fetchUrl = continuationToken ? `au/a/overview?searchTerm=${searchTermValue}&country=${selectedMarketValue}&continuationToken=${continuationToken}` : `au/a/overview?searchTerm=${searchTermValue}&country=${selectedMarketValue}`
    } else if (selectedMarketValue) {
      fetchUrl = continuationToken ? `au/a/overview?country=${selectedMarketValue}&continuationToken=${continuationToken}` : `au/a/overview?country=${selectedMarketValue}`
    } else if (searchTermValue) {
      fetchUrl = continuationToken ? `au/a/overview?searchTerm=${searchTermValue}&continuationToken=${continuationToken}` : `au/a/overview?searchTerm=${searchTermValue}`
    } else if (!searchTermValue && !selectedMarketValue && continuationToken) {
      fetchUrl = `au/a/overview?continuationToken=${continuationToken}`;
    }

    fetchGetJsonData<AudiencesData>({ 
      url: fetchUrl, 
      token: token, 
      baseUrl: "audience", 
      customerId
    }).then((res) => {
      if (continuationToken) {
        dispatch(setAudiences({ ...res, samples: [...audiencesData.samples, ...res.samples] }))
      } else {
        dispatch(setAudiences({ ...res }))
      }

      setIsLoading(false)
      setIsOverlayLoading(false);
    }).catch((error) => {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } })
    })
  }

  const updateBatchStatus = (status: AudienceStatusType, sampleId: string, projectId: string, audienceId: string, batchId: string, etag: string | null) => {
    fetchPutJsonData<{ etag: string }>({ url: `au/a/projects/${projectId}/samples/${sampleId}/audiences/${audienceId}/batches/${batchId}/status`, token: token, body: { status: status, etag: etag }, baseUrl: "audience", customerId })
      .then((res) => {
        setIsStatusLoading(false)
        const updatedSamples = audiencesData.samples.map((sample: Sample) =>
          sample.id === sampleId ?
            {
              ...sample,
              audiences: sample.audiences.map((audience) =>
                audience.id === audienceId ? {
                  ...audience,
                  batches: audience.batches.map((batch) => batch.id === batchId ?
                    {
                      ...batch,
                      etag: res.etag,
                      status
                    }
                    :
                    batch)
                }
                  :
                  audience
              )
            }
            :
            sample);

        dispatch(editProjects(updatedSamples));

        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Status updated successfully' } })
      }).then(() => {
        fetchAudiencesData(searchTerm ? searchTerm : '', selectedMarket.isoCode, audiencesData.continuationToken);
      }).catch((error) => {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } });
        setIsStatusLoading(false)
      })
  }

  const expandRow = (event: TODO, type: "sample" | "audience", sample?: Sample, audience?: AudiencesType) => {
    if (event.target.id === sample?.id && type === "sample") {
      setExpandSample(expandSample === sample?.id ? undefined : sample?.id)
      if (expandSample === sample?.id) {
        setExpandAudience(undefined);
      }
    } else if (event.target.id === audience?.id && type === "audience") {
      setExpandAudience(expandAudience === audience?.id ? undefined : audience?.id);
    }
  }

  /* 
   Check that the user is an audience admin
  */
  // const audienceAdmin = parseJwt(token);
  // const isAudienceAdmin = audienceAdmin[`${import.meta.env.VITE_APP_AUTH0_NAMESPACE}/audienceAdmin`] as unknown as boolean;
  // console.log('isAudienceAdmin: ', isAudienceAdmin, audienceAdmin);

  return (
    <section className="main audiences-container p-0">
      <div className='d-flex flex-column w-100 projects'>
        <AudiencesHeader
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          setSelectedMarket={setSelectedMarket}
          selectedMarket={selectedMarket}
          selectedTab={"All"}
          fetchAudiencesData={fetchAudiencesData}
          markets={markets}
          setIsOverlayLoading={setIsOverlayLoading}
        />

        <div className='d-flex px-6 py-5 w-100'>
          <div className='nav-board d-flex justify-content-center flex-column w-100 bg-white gap-lg'>
            <section className="position-relative h-100">
              {isOverlayLoading && (<FullHeightOverlaySpinner />)}

              <article className='project-header bg-white'>
                <div className='inner mx-0 row py-2 text-primary strong'>
                  {AudienceProjectHeaders.map((tab) => (
                    <span
                      key={tab}
                      className={`${tab === "Name" ? 'col-2' : 'col'} text-nowrap ${tab === "Name" || tab === "Project manager" ? "" : "text-center"}`}>
                      {tab}
                    </span>
                  ))}
                </div>
              </article>

              {!audiencesData?.samples?.length && isLoading && (
                <article className='d-flex w-100 project-row' style={{ height: "90svh" }}>
                  <FullHeightSpinner />
                </article>
              )}

              {audiencesData.samples?.length ? audiencesData.samples.map((sample) => (
                <Fragment key={sample.id}>
                  {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                  <div id={sample.id} onClick={(event) => expandRow(event, 'sample', sample)} className={`d-flex w-100 border-bottom project-row align-items-center ${expandSample === sample.id ? "active-row" : ""}`} style={{ minHeight: "4rem" }}>
                    <div className="col-2 d-flex flex-column gap-sm w-min-300 pe-none">
                      <div className="d-flex gap-sm">
                        <p className="m-0 text-nowrap text-truncate strong">
                          {sample.name}
                        </p>
                        <p className='text-muted normal m-0'>
                          {sample.audiences.length > 0 && `(${sample.audiences.length})`}
                        </p>
                      </div>
                    </div>

                    <p className="col text-nowrap align-items-center text-truncate w-min-250 m-0 pe-none">
                      {sample.createdByName}
                    </p>

                    <p className="d-flex col m-0 text-nowrap align-items-center justify-content-center pe-none">
                      <span className={`audiences-status ${sample.totalStatus}`} />
                    </p>

                    <p className="d-flex col m-0 text-nowrap align-items-center justify-content-center  text-truncate pe-none" title={sample.sumCompletes?.toString()}>
                      {sample.sumCompletes}
                    </p>

                    {/* <p className="d-flex col m-0 text-nowrap align-items-center justify-content-center  text-truncate pe-none" title={sample.avgCPI?.toFixed(2)}>
                      {sample.avgCPI?.toFixed(2)}
                    </p> */}

                    <p className="d-flex col m-0 text-nowrap align-items-center justify-content-center  text-truncate pe-none">
                      {Number(sample.incidenceRateStats?.actual) ? `${Math.round(sample.incidenceRateStats?.actual)}%` : <span className='text-empty'>-</span>}
                      {
                        Number(sample.incidenceRateStats?.difference) ?
                          <span className='text-muted ml-1'>{`(${Math.round(sample.incidenceRateStats?.difference)}%)`} </span>
                          :
                          null
                      }
                    </p>

                    <p className="d-flex col m-0 text-nowrap align-items-center justify-content-center  text-truncate pe-none">
                      {Number(sample.loi?.actual) ? `${Math.round(sample.loi?.actual)}%` : <span className='text-empty'>-</span>}
                      {
                        Number(sample.loi?.difference) ?
                          <span className='text-muted ml-1'>{`(${Math.round(sample.loi?.difference)}%)`} </span>
                          :
                          null
                      }
                    </p>

                    <span className='d-flex col align-items-center justify-content-end' id='dropdown-button-content'>
                      <AudienceActionsDropdown setIsOverlayLoading={setIsOverlayLoading} sample={sample} type='sample' />
                    </span>
                  </div>
                  {
                    sample.audiences.length > 0 && expandSample === sample.id && sample.audiences.map((audience) =>
                      <Fragment key={audience.id}>
                        {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                        <div id={audience.id} className='m-0 row project-sub-row cursor-pointer' onClick={(event) => { expandRow(event, 'audience', sample, audience) }}>
                          <div className="col-2 d-flex flex-column w-min-300 pe-none">
                            <div className='d-flex gamp-sm'>
                              <span className="text-nowrap text-truncate mr-2 mb-1">
                                <Link
                                  style={{ pointerEvents: "auto" }}
                                  className='strong' to={`/projects/${sample.projectId}/samples/${sample.id}/audience/${audience.id}`}
                                  title={audience.displayName}
                                  onClick={() => dispatch(editAudiencesNavigation({ projectId: sample.projectId, sampleId: sample.id, audienceId: audience.id }))}
                                >
                                  {audience.displayName}
                                </Link>
                              </span>
                              <p className='text-muted normal m-0'>
                                {audience.batches.length && `(${audience.batches.length})`}
                              </p>
                            </div>

                            <div className="d-flex gap-md">
                              <span className='badge'>
                                {markets.find((item) => item.isoCode === audience.countryCode)?.name}
                              </span>

                              <span className="badge">
                                {audience.isOpen ? 'Open' : 'Closed'}
                              </span>
                            </div>
                          </div>

                          <p className="d-flex col m-0 text-nowrap align-items-center text-truncate w-min-250 pe-none">{audience.createdByEmail}</p>

                          <p className="d-flex col m-0 align-items-center justify-content-center pe-none">
                            <span className={`audiences-status ${audience.totalStatus}`} />
                          </p>

                          <p className="d-flex col text-nowrap align-items-center text-truncate justify-content-center  m-0 pe-none" title={audience.sumCompletes?.toString()}>
                            {audience.sumCompletes}
                          </p>

                          {/* <p className="d-flex col m-0 text-nowrap align-items-center justify-content-center text-truncate pe-none" title={audience.avgCPI?.toFixed(2)}>
                            {audience.avgCPI?.toFixed(2)}
                          </p> */}

                          <p className="d-flex col m-0 text-nowrap align-items-center justify-content-center text-truncate pe-none">
                            {Number(audience.incidenceRateStats?.actual) ? `${Math.round(audience.incidenceRateStats?.actual)}%` : <span className='text-empty'>-</span>}
                            {
                              Number(audience.incidenceRateStats?.difference) ?
                                <span className='text-muted ml-1'>{`(${Math.round(audience.incidenceRateStats?.difference)}%)`} </span>
                                :
                                null
                            }
                          </p>

                          <p className="d-flex col m-0 text-nowrap align-items-center justify-content-center text-truncate pe-none">
                            {Number(audience.loi?.actual) ? `${Math.round(audience.loi?.actual)}%` : <span className='text-empty'>-</span>}
                            {
                              Number(audience.loi?.difference) ?
                                <span className='text-muted ml-1'>{`(${Math.round(audience.loi?.difference)}%)`} </span>
                                :
                                null
                            }
                          </p>

                          <div className='d-flex col align-items-center justify-content-end'>
                            <AudienceActionsDropdown setIsOverlayLoading={setIsOverlayLoading} sample={sample} audience={audience} type='audience' />
                          </div>
                        </div>
                        {
                          expandAudience === audience.id && audience.batches?.map((batch) =>
                            <div key={batch.id} className='m-0 row project-sub-row project-second-sub-row ml-05'>
                              <div className="col-2 d-flex flex-column w-min-300">
                                <span className="text-nowrap text-truncate mr-2 mb-1">
                                  <Link
                                    className='strong'
                                    to={`/projects/${sample.projectId}/samples/${sample.id}/audience/${audience.id}/batch/${batch.id}`}
                                    title={batch.displayName}
                                    onClick={() => dispatch(editAudiencesNavigation({ projectId: sample.projectId, sampleId: sample.id, audienceId: audience.id, batchId: batch.id }))}
                                  >
                                    {batch.displayName}
                                  </Link>
                                </span>

                                <span className='d-flex flex-row'>
                                  <DropdownButton
                                    items={statuses}
                                    className={`btn btn-shadow small h-24 btn-icon icon-r ${updateStatusInputClasses[batch.status]}`}
                                    text={batch.status}
                                    parentClass='status-dropdown'
                                    disabled={isStatusLoading}
                                    onItemClick={(e) => {
                                      setIsStatusLoading(true);
                                      updateBatchStatus(e.item.text, sample.id, sample.projectId, audience.id, batch.id, batch.etag)
                                    }}
                                  />
                                </span>
                              </div>

                              <p className="d-flex col m-0 text-nowrap align-items-center text-truncate w-min-250">{batch.projectManagerEmail}</p>

                              <p className="d-flex col m-0 text-nowrap align-items-center justify-content-center text-truncate pe-none">
                                {batch.status}
                              </p>

                              <p className="d-flex col text-nowrap align-items-center justify-content-center text-truncate m-0">
                                {batch.completes}
                              </p>

                              {/* <p className="d-flex col m-0 text-nowrap align-items-center justify-content-center text-truncate">
                                {batch.currencySymbol}{batch.cpi?.toFixed(2)}
                              </p> */}

                              <p className="d-flex col m-0 text-nowrap align-items-center justify-content-center text-truncate">
                                {Number(batch.incidenceRateStats?.actual) ? `${Math.round(batch.incidenceRateStats?.actual)}%` : <span className='text-empty'>-</span>}
                                {
                                  Number(batch.incidenceRateStats?.difference) ?
                                    <span className='text-muted ml-1'>{`(${Math.round(batch.incidenceRateStats?.difference)}%)`} </span>
                                    :
                                    null
                                }
                              </p>

                              <p className="d-flex col m-0 text-nowrap align-items-center justify-content-center text-truncate">
                                {Number(batch.incidenceRateStats?.actual) ? `${Math.round(batch.incidenceRateStats?.actual)}%` : <span className='text-empty'>-</span>}
                                {
                                  Number(batch.incidenceRateStats?.difference) ?
                                    <span className='text-muted ml-1'>{`(${Math.round(batch.incidenceRateStats?.difference)}%)`} </span>
                                    :
                                    null
                                }
                              </p>

                              <div className='d-flex col align-items-center justify-content-end'>
                                <AudienceActionsDropdown setIsOverlayLoading={setIsOverlayLoading} sample={sample} audience={audience} batch={batch} type='batch' />
                              </div>
                            </div>
                          )
                        }
                      </Fragment>
                    )
                  }
                </Fragment>
              ))
                :
                <AudiencesNoProjects searchTerm={searchTerm} selectedMarket={selectedMarket} />
              }
            </section>

            {audiencesData.continuationToken ? <div className='d-flex justify-content-center w-100 border-top'>
              <button
                onClick={() => {
                  fetchAudiencesData(searchTerm ? searchTerm : '', selectedMarket.isoCode, audiencesData.continuationToken)
                  setIsOverlayLoading(true);
                }}
                className="btn btn-shadow my-4" type="button">
                Load more
              </button>
            </div> : null}
          </div>
        </div>
        {
          errorModal.show &&
          <ErrorMessage
            type="modal"
            errorMessage={errorModal.message}
            onHide={() => setErrorModal({ show: false, message: '' })}
          />
        }
      </div>
    </section >
  )
}