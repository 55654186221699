import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import type { BatchOverviewType, QuotaTargets } from '../../../../interfaces/auddineceAccessInterfaces/batchOverviewTypes';
import type { RootState } from '../../../../store/reducers/rootReducer';
import { Icon } from '../../../shared/Icon/Icon';
import { setBatch } from '../../../../store/reducers/batchReducer/batchReducer';
import { fetchPutJsonData } from '../../../../services/services';

type Props = {
  setShowQuotaModal: (show: boolean) => void;
}

export const TagretingQuota = ({ setShowQuotaModal }: Props) => {
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { customerId } = useSelector((state: RootState) => state.audienceAdminReducer);
  const batchData = useSelector((state: RootState) => state.batchDataReducer);

  const params: { projectId: string, audienceId: string, sampleId: string, batchId: string } = useParams();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [expandAll, setExpandAll] = useState(false);
  const [quantity, setQuantity] = useState(batchData.quantity.toString())

  const handleSaveQuantity = () => {
    if (Number(quantity) <= 0) {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Must be a positive number" } });
      return;
    }
    setIsLoading(true);

    const updatedData = {
      displayName: batchData.displayName,
      language: batchData.market.language,
      country: batchData.market.language,
      currency: batchData.currency,
      targetType: batchData.targetType,
      launchDate: batchData.launchDate,
      targetEndDate: batchData.targetEndDate,
      quantity: Number(quantity),
      lengthOfInterview: Number(batchData.lengthOfInterview),
      incidenceRate: Number(batchData.incidenceRate),
      clientCPI: Number(batchData.clientCPI),
      cpi: Number(batchData.cpi),
      completeAsap: batchData.completeAsap,
      integrationPartners: [],
      targeting: batchData.targeting,
      etag: batchData.etag ? batchData.etag : null,
    }

    fetchPutJsonData<BatchOverviewType>({ url: `au/a/projects/${params.projectId}/samples/${params.sampleId}/audiences/${params.audienceId}/batches/${params.batchId}`, token: token, body: updatedData, baseUrl: "audience", customerId: customerId })
      .then((res) => {
        setIsLoading(false)
        dispatch(setBatch({ ...res, batchAction: "edit" }))
        dispatch({ type: 'SHOW_ACTION_NOTIFICATION', payload: { msg: 'Target completes successfully' } })
      }).catch((error) => {
        setIsLoading(false)
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } });
      })
  }

  return (
    <article className='nav-board d-flex flex-column bg-white answer-layout p-5 gap-md'>
      <div className='d-flex justify-content-between'>
        <p className="innerboard-header d-flex align-items-center stronger h-32 m-0">Quotas</p>
        <div className='d-flex gap-md'>
          <button type='button' className='btn btn-secondary' onClick={() => setExpandAll(!expandAll)}>
            {expandAll ? "Collapse all" : "Expand all"}
          </button>
          <button type='button' className='btn btn-primary' onClick={() => setShowQuotaModal(true)}>
            {batchData.targeting?.quotas === null || batchData.targeting?.quotas?.length === 0 ? "Add quotas" : "Edit quotas"}
          </button>
        </div>
      </div>

      {batchData.targeting?.quotas === null || batchData.targeting?.quotas?.length === 0 ?
        <div className='board-content-container' >
          <table className="table target-table border mb-0">
            <tbody>
              <tr>
                <td className='d-flex justify-content-center p-4 w-100 m-0 text-muted'>No quotas</td>
              </tr>
            </tbody>
          </table>
        </div>
        :
        <div className='board-content-container' >
          <table className="table target-table mb-0 border rounded-0" style={{ tableLayout: "fixed" }}>
            <tbody>
              <tr>
                <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Quota name</th>
                <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Completes</th>
                <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Quota target</th>
                <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Completes remaining </th>
                <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Entrants</th>
                <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Conversion</th>
              </tr>
              <tr >
                <th className='align-middle' scope="row">
                  Total
                </th>
                <td className='text-left align-middle py-0' width={"15%"}>{0}</td>
                <td className='text-left py-0 align-middle' width={"15%"} >
                  <div className='d-flex'>
                    <input type="numeric" className="form-control w-50" name={batchData.id} value={quantity} disabled={batchData.batchAction === "copy"} onChange={(e) => setQuantity(e.target.value)} />
                    <button className='d-flex align-items-center justify-content-center btn-transparent' type='button' disabled={isLoading || batchData.batchAction === "copy"} onClick={handleSaveQuantity} style={{ width: "36px" }}>
                      {
                        isLoading ?
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                          :
                          <i className="fa-solid fa-lg fa-floppy-disk pe-none" />
                      }
                    </button>
                  </div>
                </td>
                <td className='text-left align-middle py-0' width={"15%"}>{batchData.quantity ? batchData.quantity : 0}</td>
                <td className='text-left align-middle py-0' width={"15%"}>{0}</td>
                <td className='text-left align-middle py-0' width={"15%"}>{batchData.incidenceRate ? `${batchData.incidenceRate}%` : 0}</td>
              </tr>
            </tbody>
          </table>
        </div>
      }

      {batchData.targeting?.quotas?.map((quota, index) =>
      (
        <div key={quota.questionId ? quota.questionId : index} className="accordion h-100" id="accordionExample">
          <div className="accordion-item table-shadow">
            <div className='board-content-container'>

              <div id={`heading${quota.questionId}`} className='d-flex justify-content-between align-items-center p-2' data-toggle="collapse" data-target={`#main${quota.questionId}`} aria-expanded="false" aria-controls="main">
                <div className='d-flex align-items-center gap-md'>
                  <p className='text-indigo-dark strong m-0' >{quota.name}</p>
                  <h6 className='text-primary large strong p-0 m-0' >{quota.text}</h6>
                </div>

                <Icon type="chevron-mini-down-alt" className="accordion-icon" />
              </div>

              <table className="table target-table mb-0 border-0 rounded-0" style={{ tableLayout: "fixed" }}>
                <tbody
                  id={`main${quota.questionId}`}
                  className={`collapse ${expandAll ? "show" : ""}`}
                  aria-labelledby={`heading${quota.questionId}`}
                >
                  <tr>
                    <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Quota name</th>
                    <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Completes</th>
                    <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Quota target</th>
                    <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Completes remaining </th>
                    <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Entrants</th>
                    <th className='text-muted font-weight-normal border-0 align-middle' scope="col">Conversion</th>
                  </tr>
                  {
                    quota?.targets?.map((item: QuotaTargets, index) => (
                      <tr key={item.id ? item.id : index} >
                        <th scope="row">
                          <div className='d-flex flex-row flex-wrap align-items-center h-100 gap-sm'>
                            {item.answers.map((answer) => (
                              <p key={answer.id} title={answer.text && answer.text} className='text-nowrap text-truncate small strong badge px-2 py-1 m-0' >{answer.text ? answer.text : `${answer.start} to ${answer.end}`}</p>
                            ))}
                          </div></th>
                        <td className='text-left align-middle py-0' width={"15%"}>{item.completes || 0}</td>
                        <td className='text-left py-0 align-middle' width={"15%"} >
                          <div className='d-flex'>
                            <input type="text" className="form-control w-50" disabled name={item.id ? item.id.toString() : index.toString()} value={item.targetCompletes} />
                          </div>
                        </td>
                        <td className='text-left align-middle py-0' width={"15%"}>{item.completesRemaining}</td>
                        <td className='text-left align-middle py-0' width={"15%"}>{item.entrants || 0}</td>
                        <td className='text-left align-middle py-0' width={"15%"}>{item.incidenceRate ? `${item.incidenceRate}%` : 0}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ))}
    </article>
  )
}