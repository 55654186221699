export interface ImpersonationBannerProps {
  userFullName: string | null;
  customer?: string;
  loggedAsTitle?: string;
  logoutButtonTitle?: string;
  logoutImpersonatedUser?: () => void;

}

export const ImpersonationBanner = ({customer, userFullName, loggedAsTitle = 'Logged in as', logoutButtonTitle = 'Log out', logoutImpersonatedUser} : ImpersonationBannerProps) => {
  
  return (
    <div id="user-impersonation" className="mt-3 d-flex justify-content-between">
      <p className='mb-0 text-truncate'>{loggedAsTitle} <span className='strong'>{userFullName}</span> {customer && <span>({customer})</span>}</p>
      {logoutImpersonatedUser && <button type="button" onClick={logoutImpersonatedUser} className="btn btn-sm bg-white text-primary strong ml-3">{logoutButtonTitle}</button>}
    </div>
  )   
}
