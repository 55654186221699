import { combineReducers } from "redux";
import adminReducer from "./adminReducer/adminReducer";
import projectsReducer from "./projectsReducer/projectsReducer";
import digStateReducer from "./digStateReducer/digStateReducer";
import userStateReducer from "./userStateReducer/userStateReducer";
import stateReducer from "./analyzeStateReducer/analyzeStateReducer";
import userSettingsReducer from "./userSettingsReducer/userSettingsReducer";
import chartStateReducer from "./chartStateReducer/chartStateReducer";
import recentDataReducer from "./recentDataReducer/recentDataReducer";
import panelStateReducer from "./panelStateReducer/panelStateReducer";
import qTableStateReducer from "./qTableStateReducer/qTableStateReducer";
import exportStateReducer from "./exportStateReducer/exportStateReducer";
import scriptStateReducer from "./scriptStateReducer/scriptStateReducer";
import toplineStateReducer from "./toplineStateReducer/toplineStateReducer";
import rawDataStateReducer from "./rawDataStateReducer/rawDataStateReducer";
import pinboardStateReducer from "./pinboardStateReducer/pinboardStateReducer";
import workflowStateReducer from "./workflowStateReducer/workflowStateReducer";
import breadcrumbStateReducer from "./breadcrumStateReducer/breadcrumbStateReducer";
import fileManagerStateReducer from "./fileManagerStateReducer/fileManagerStateReducer";
import setInitialDataReducer from "./analyzeInitialDataReducer/analyzeInitialDataReducer";
import surveyInitialDataReducer from "./surveyInitialDataReducer/surveyInitialDataReducer";
import errorMessageStateReducer from "./errorMessageStateReducer/errorMessageStateReducer";
import quickImportStatusReducer from "./quickImportStatusReducer/quickImportStatusReducer";
import advancedImportStatusReducer from "./advancedImportStatusReducer/advancedImportStatusReducer";
import inboxNotificationStateReducer from "./inboxNotificationStateReducer/inboxNotificationStateReducer";
import analysisScriptingStateReducer from "./analysisScriptingStateReducer/analysisScriptingStateReducer";
import actionNotificationStateReducer from "./actionNotificationStateReducer/actionNotificationStateReducer";
import surveyCustomQuestionsStateReducer from "./surveyCustomQuestionsStateReducer/surveyCustomQuestionsStateReducer";
import projectExternalShareStateReducer from "./projectExternalShareStateReducer/projectExternalShareStateReducer";
import reportStateReducer from "./reportStateReducer/reportStateReducer";
import audienceDataReducer from "./audienceAccessReducer/audienceAccessReducer";
import gatekeeperStateReducer from "./gatekeeperStateReducer/gatekeeperStateReducer";
import tokenStateReducer from "./tokenStateReducer/tokenStateReducer";
import audiencesDataReducer from "./audiencesReducer/audiencesReducer";
import batchDataReducer from "./batchReducer/batchReducer";
import audiencesNavigationReducer from "./audiencesNavigationReducer/audiencesNavigationReducer";
import audienceAdminReducer from "./AudienceAdminReducer/audienceAdminReducer";

export const rootReducer = combineReducers({
  stateReducer,
  digStateReducer,
  userStateReducer,
  chartStateReducer,
  recentDataReducer,
  panelStateReducer,
  exportStateReducer,
  qTableStateReducer,
  scriptStateReducer,
  toplineStateReducer,
  rawDataStateReducer,
  pinboardStateReducer,
  workflowStateReducer,
  setInitialDataReducer,
  fileManagerStateReducer,
  errorMessageStateReducer,
  quickImportStatusReducer,
  surveyInitialDataReducer,
  advancedImportStatusReducer,
  inboxNotificationStateReducer,
  analysisScriptingStateReducer,
  actionNotificationStateReducer,
  surveyCustomQuestionsStateReducer,
  projectExternalShareStateReducer,
  userSettingsReducer,
  reportStateReducer,
  adminReducer: adminReducer,
  projectsReducer: projectsReducer,
  breadcrumbStateReducer: breadcrumbStateReducer,
  audienceDataReducer,
  gatekeeperStateReducer,
  tokenStateReducer,
  audiencesDataReducer,
  batchDataReducer,
  audiencesNavigationReducer,
  audienceAdminReducer
});

export type RootState = ReturnType<typeof rootReducer>;
