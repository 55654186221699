// Pre-Screener is the 'Walr Verify'
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import type { RootState } from "../../../store/reducers/rootReducer";
import { Icon } from "../../shared/Icon/Icon";
import { FullHeightSpinner } from "../../shared/FullHeightSpinner/FullHeightSpinner";
import { returnQuesElementIcon } from "../../Survey/SurveyTabContent/SurveyDesignTabContent/helpers/returnDataHelpers/returnDataHelpers";
import type { DraggableEvent, PreScreenerSurveyQuestion, PreScreening } from "../../../interfaces/auddineceAccessInterfaces/audiencesTypes";
import { editEtag, editPrescreening } from "../../../store/reducers/audienceAccessReducer/audienceAccessReducer";
import type { PreScreenerPublishSurveyType, PreScreenerSurveyLinkType } from "../../../interfaces/auddineceAccessInterfaces/audienceOverviewTypes";
import { ErrorMessage } from "../../shared/ErrorMessage/ErrorMessage";
import { fetchGetJsonData, fetchPatchJsonData, fetchPostJsonData } from "../../../services/services";
import { PreScreenerSuccess } from "./PreScreenerSuccess";

export const PreScreener = () => {
  const { etag, displayName, prescreening, shortKey } = useSelector((state: RootState) => state.audienceDataReducer);
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { customerId } = useSelector((state: RootState) => state.audienceAdminReducer);

  const params: { projectId: string, audienceId: string, sampleId: string, batchId: string } = useParams();
  const { projectId, sampleId, audienceId } = params;
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingQuestions, setIsLoadingQuestions] = useState(false);
  const [preScreenings, setPreScreenings] = useState<PreScreening[]>([{ name: "", id: "", projectId: "" }]);
  const [selectedPreScreen, setSelectedPreScreen] = useState<PreScreening>({ name: prescreening?.questionLibrary?.name, id: prescreening?.questionLibrary?.id, projectId: prescreening?.questionLibrary?.projectId });
  const [searchPreScreening, setSearchPreScreening] = useState("");
  const [surveyQuestions, setSurveyQuestions] = useState<PreScreenerSurveyQuestion[]>([]);
  const [selectedQuestions, setSelectedQuestions] = useState<PreScreenerSurveyQuestion[]>(prescreening?.selectedQuestions ? prescreening?.selectedQuestions : []);

  const handleSearch = (questionLibSearch: string) => {
    setSearchPreScreening(questionLibSearch);
  }

  useEffect(() => {
    if (projectId && sampleId && audienceId) {
      fetchGetJsonData<PreScreening[]>({ url: "su/projects/surveys/prescreenings", token: token, baseUrl: "default", customerId: customerId })
        .then((res) => {
          setPreScreenings(res);
        }).catch((error) => {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } })
        })
    }
  }, [dispatch, token, projectId, sampleId, audienceId, customerId]);

  const filterQuestion = (questions: PreScreenerSurveyQuestion[]) => {
    const filteredQuestions = questions.filter((item) => selectedQuestions?.every((selectedQuestion) => selectedQuestion.questionId !== item.questionId));
    setSurveyQuestions(filteredQuestions);
  }

  useEffect(() => {
    if (selectedPreScreen.projectId && selectedPreScreen.id) {
      setIsLoadingQuestions(true);
      fetchGetJsonData<PreScreenerSurveyQuestion[]>({ url: `su/projects/${selectedPreScreen.projectId}/prescreening/${selectedPreScreen.id}/questions`, token: token, baseUrl: "default", customerId: customerId })
        .then((res) => {
          filterQuestion(res)
          setIsLoadingQuestions(false);
        }).catch((error) => {
          dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } });
          setIsLoadingQuestions(false);
        })
    }
  }, [dispatch, token, selectedPreScreen, customerId])

  const handleSelectPreScreen = (preScreen: PreScreening) => {
    if (preScreen?.id && preScreen?.projectId) {
      setSelectedQuestions([]);
      setSelectedPreScreen(preScreen);
    }
  }

  const handleAddQuestion = (question: PreScreenerSurveyQuestion) => {
    if (prescreening?.isPublished) return;

    setSurveyQuestions(currentItems => currentItems?.filter((item) => item.questionId !== question.questionId))
    setSelectedQuestions(currentItems => currentItems ? [...currentItems, question] : [question]);
  }

  const handleRemoveSelectedQuestion = (question: PreScreenerSurveyQuestion) => {
    if (prescreening?.isPublished) return;

    setSelectedQuestions(currentItems => currentItems?.filter((item) => item.questionId !== question.questionId))
    setSurveyQuestions(currentItems => currentItems ? [...currentItems, question] : [question]);
  }

  const createSurveyId = async (): Promise<string | boolean> => {
    const createSurveyData = {
      displayName: `${displayName}-${shortKey}`,
      questionIds: selectedQuestions?.flatMap((item) => {
        return item.gotoQuestionId ? [item.questionId, item.gotoQuestionId] : item.questionId
      })
    }

    try {
      const { createdSurveyId } = await fetchPostJsonData<{ createdSurveyId: string }>({ url: `su/projects/${projectId}/surveys/pre-screening-from-survey/${selectedPreScreen?.projectId}/${selectedPreScreen?.id}`, token: token, body: createSurveyData, baseUrl: "default", customerId: customerId });
      return createdSurveyId
    } catch (error) {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } });
      return false
    }
  }

  const publishSurvey = async (surveyId: string | boolean): Promise<PreScreenerPublishSurveyType | boolean> => {
    if (typeof surveyId === 'string') {
      try {
        return await fetchPostJsonData<PreScreenerPublishSurveyType>({ url: `su/projects/${projectId}/surveys/${surveyId}/publish`, token: token, body: { comment: "" }, baseUrl: "default", customerId: customerId });
      } catch (error) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } });
        return error as PreScreenerPublishSurveyType
      }
    }
    return false
  }

  const createSurveyUrl = async (surveyId: string | boolean) => {
    if (typeof surveyId === 'string') {
      try {
        const { url } = await fetchPostJsonData<PreScreenerSurveyLinkType>({ url: `projects/${projectId}/surveys/${surveyId}/campaigns/link`, token: token, body: { "name": "Audience Prescreener Link", "description": "", "tags": [], "type": null }, baseUrl: "default", customerId: customerId });
        return url
      } catch (error) {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } });
        return false
      }
    }
    return false
  }

  const handleSaveQuestions = async () => {
    setIsLoading(true);
    try {
      const createdSurveyId = await createSurveyId();

      const publishedSurvey = await publishSurvey(createdSurveyId);

      const createdSurveyLink = await createSurveyUrl(createdSurveyId);

      const patchBody = {
        surveyId: createdSurveyId ? createdSurveyId.toString() : "",
        projectId: projectId,
        surveyUrl: createdSurveyLink ? `${createdSurveyLink}?WID=[%WID%]` : "",
        questionLibrary: selectedPreScreen,
        isPublished: typeof publishedSurvey === "object" ? publishedSurvey.isPublished : false,
        selectedQuestions: selectedQuestions,
        etag: etag ? etag : null
      }

      const etagRes = await fetchPatchJsonData<{ etag: string }>(
        {
          url: `au/a/projects/${projectId}/samples/${sampleId}/audiences/${audienceId}/prescreening`,
          token: token,
          body: patchBody,
          baseUrl: "default",
          customerId: customerId
        });

      if (etagRes) {
        const prescreenerData = {
          surveyId: patchBody.surveyId,
          projectId: patchBody.projectId,
          surveyUrl: patchBody.surveyUrl,
          questionLibrary: patchBody.questionLibrary,
          isPublished: patchBody.isPublished,
          selectedQuestions: patchBody.selectedQuestions
        }

        dispatch(editEtag(etagRes.etag));
        dispatch(editPrescreening(prescreenerData))
      }
      setIsLoading(false);
    } catch (error) {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } });
      setIsLoading(false);
    }
  }

  const reorder = (list: PreScreenerSurveyQuestion[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result: DraggableEvent) => {
    if (!result.destination || !selectedQuestions) {
      return;
    }

    const items = reorder(
      selectedQuestions,
      result.source.index,
      result.destination.index
    );

    setSelectedQuestions(items);
  }

  return (
    <section className="d-flex flex-column p-5 bg-white gap-xxl audience-prescreener">
      <article className="d-flex flex-column gap-lg">
        <p className="stronger m-0" style={{ fontSize: "1rem" }}>
          Walr Verify
        </p>
        {
          prescreening && (prescreening.isPublished && prescreening.surveyUrl) &&
          <PreScreenerSuccess prescreening={prescreening} />
        }

        {
          prescreening && !prescreening?.isPublished &&
          <ErrorMessage
            type="alert"
            errorMessage="Survey is not published." />
        }

        <div className="dropdown details-dropdown d-flex flex-column w-50 gap-sm">
          <p className='p-0 m-0 strong text-primary details-input-text'>
            Choose the question library
          </p>
          <button className={"btn dropdown-toggle form-control"} type="button" id="dropdownMenuAction" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" disabled={prescreening?.isPublished}>
            {selectedPreScreen?.name || "Choose question library"}
            <span>
              <Icon type="chevron-mini-down-alt" className="submenu" />
            </span>
          </button>

          <div className="dropdown-menu menu-action" aria-labelledby="dropdownMenuAction">
            <div className='d-flex px-3 pb-3 border-bottom'>
              <input className='form-control' type="text" placeholder="Search libraries" id="myInput" onChange={(e) => handleSearch(e.target.value)} />
            </div>

            <div className='d-flex flex-column market overflow-auto'>
              {preScreenings?.filter(preScreening => preScreening?.name?.toLocaleLowerCase().includes(searchPreScreening)).map((item) => (
                <button
                  key={item.name}
                  className="dropdown-item"
                  type="button"
                  onClick={() => handleSelectPreScreen(item)}
                >
                  {item.name}
                </button>
              ))}
            </div>
          </div>
        </div>
      </article>

      <article className="d-flex gap-lg">
        <div className="d-flex flex-column gap-sm w-50">
          <p className="m-0">Available questions</p>
          <div className={`d-flex flex-column gap-sm border border rounded p-2 overflow-auto ${prescreening?.isPublished && "pe-none audience-prescreener-disabled"}`} style={{ height: "300px" }}>
            {isLoadingQuestions ?
              <FullHeightSpinner />
              :
              surveyQuestions && surveyQuestions.length > 0 ?
                surveyQuestions.map((question) =>
                  // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
                  <div key={question.questionId} onClick={() => handleAddQuestion(question)} className="d-flex gap-md align-items-center p-2 border border-secondary h-48 audience-prescreener-question" style={{ cursor: "pointer" }}>
                    <Icon className="audience-prescreener-question-icon" type={returnQuesElementIcon(question.referenceQuesTypes?.at(0))} />
                    <p className="m-0">{question.label}</p>
                  </div>
                )
                :
                <div className="d-flex justify-content-center align-items-center h-100">
                  <p className="m-0">No questions avaliable</p>
                </div>
            }
          </div>
        </div>

        <div className="d-flex flex-column gap-sm w-50">
          <p className="m-0">Selected questions</p>
          <div className={`d-flex flex-column border rounded p-2 overflow-auto ${prescreening?.isPublished && "pe-none audience-prescreener-disabled"}`} style={{ height: "300px" }}>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className="d-flex flex-column gap-sm h-100"
                  >
                    {selectedQuestions && selectedQuestions.length > 0 ? selectedQuestions?.map((item, index) => (
                      <Draggable key={item.questionId} draggableId={item.questionId} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <div key={item.questionId} className="d-flex justify-content-between align-items-center p-2 border border-secondary h-48 audience-prescreener-question">
                              <div className="d-flex gap-md">
                                <Icon className="audience-prescreener-question-icon" type={returnQuesElementIcon(item.referenceQuesTypes?.at(0))} />
                                <p className="m-0">{item.label}</p>
                              </div>
                              <button type="button" className="btn btn-transparent" onClick={() => handleRemoveSelectedQuestion(item)}>
                                <Icon type="close" />
                              </button>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    ))
                      :
                      <div className="d-flex justify-content-center align-items-center h-100">
                        <p className="m-0">No selected questions</p>
                      </div>
                    }
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </div>
      </article>

      <div className="d-flex justify-content-end">
        <button type="button" className="btn btn-primary" disabled={prescreening?.isPublished || !surveyQuestions || isLoading} onClick={() => handleSaveQuestions()}>
          Save
          {
            isLoading &&
            <span className="spinner-border spinner-border-sm ml-1" role="status" aria-hidden="true" />
          }
        </button>
      </div>
    </section >
  )
}