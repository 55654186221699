import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { filterBy } from '@progress/kendo-data-query';
import { MultiSelect, type MultiSelectFilterChangeEvent } from '@progress/kendo-react-dropdowns';

import type { RootState } from '../../../../store/reducers/rootReducer';
import type { ValidationError } from '../../../shared/Zod/handleZodValidation';
import type { audienceSchema } from '../../../shared/Zod/ZodSchemas/audienceSchema';
import { fetchGetJsonData } from '../../../../services/services';
import type { AudienceToExclude } from '../../../../interfaces/auddineceAccessInterfaces/audienceOverviewTypes';

type Props = {
  countryCode: string;
  errors?: ValidationError<typeof audienceSchema>;
  showExclusions: boolean
}

type AudiencesByCountry = {
  audiencesByCountry: AudienceToExclude[]
}

const EXC_STATUS: string[] = ["Complete", "ScreenOut", "QuotaOut", "BadQuality", "DropOff"];

export const Exclusions = ({ countryCode, errors, showExclusions }: Props) => {
  const { exclusion, projectId, sampleId, id } = useSelector((state: RootState) => state.audienceDataReducer);
  const { token } = useSelector((state: RootState) => state.tokenStateReducer);
  const { customerId } = useSelector((state: RootState) => state.audienceAdminReducer);

  const [audiencesToExclude, setAudiencesToExclude] = useState<AudienceToExclude[]>([]);
  const [audiencesNames, setAudiencesNames] = useState<AudienceToExclude[]>([]);
  const [respondentStatuses, setRespondentStatuses] = useState(showExclusions && exclusion?.respondentStatuses ? exclusion?.respondentStatuses : ["Complete"]);
  const [audiences, setAudiences] = useState(showExclusions && exclusion ? exclusion?.audiences : [])

  const dispatch = useDispatch();

  useEffect(() => {
    if (countryCode !== "") {
      fetchGetJsonData<AudiencesByCountry>({ 
        url: `au/a/projects/${projectId}/samples/${sampleId}/audiences/country?countryCode=${countryCode}`, 
        token: token, 
        baseUrl: "default", 
        customerId: customerId  
      })
      .then((res) => {
        setAudiencesToExclude(res.audiencesByCountry.filter((item) => item.id !== id && item.displayName && item));
        setAudiencesNames(res.audiencesByCountry.filter((item) => item.id !== id && item))
      }).catch((error) => {
        dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: error } })
      })
    } else {
      dispatch({ type: 'SHOW_ERROR_NOTIFICATION', payload: { msg: "Select market to exclude surveys" } })
    }
  }, [countryCode, token, dispatch, projectId, sampleId, id]);

  const filterChange = (event: MultiSelectFilterChangeEvent) => {
    if (audiencesToExclude) {
      setAudiencesNames(filterBy(audiencesToExclude, event.filter));
    }
  }

  return (
    <div className="details-input d-flex flex-column p-2 border rounded">
      <div className='row row-col-2 px-3'>
        <div className="details-input d-flex flex-column w-50 ">
          <p className='p-0 m-0 strong text-primary'>Exclusion status</p>
          <MultiSelect
            data={EXC_STATUS}
            onChange={(e) => setRespondentStatuses(() => [...e.value])}
            value={respondentStatuses}
            placeholder="Multi-select ..."
          />

          <input hidden readOnly value={respondentStatuses.length >= 1 ? JSON.stringify(respondentStatuses) : respondentStatuses} name='respondentStatuses' />
        </div>

        <div className="details-input d-flex flex-column w-50 ">
          <p className='p-0 m-0 strong text-primary'>Exclusion duration</p>
          <div className="input-group">
            <input
              className='form-control'
              type="numeric"
              placeholder='0'
              min={1}
              name='exclusionDurationDays'
              defaultValue={exclusion ? exclusion?.exclusionDurationDays : "90"}
            />
            <div className="input-group-append">
              <span aria-disabled="true" className="input-group-text py-1 time-span">days</span>
            </div>
          </div>
        </div>
      </div>

      <div className="details-input d-flex flex-column ">
        <p className='p-0 m-0 strong text-primary'>Surveys to exclude</p>
        <MultiSelect
          data={audiencesNames}
          filterable={true}
          onFilterChange={filterChange}
          onChange={(e) => setAudiences(() => [...e.value])}
          value={audiences}
          textField="displayName"
          dataItemKey="id"
          placeholder="Please select ..."
        />

        <input hidden readOnly value={audiences.length ? JSON.stringify(audiences) : JSON.stringify(audiences)} name='audienceIds' />

        {errors?.exclusion &&
          <div className="text-danger">
            <small>{errors?.exclusion}</small>
          </div>}
      </div>
    </div>
  )
}
