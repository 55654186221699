import { useEffect } from 'react';

import { moveJsxElement } from '../helpers/moveJsxElement/moveJsxElement';

interface Props {
  extendedUserData: {
    customer: string | null
  }
}

export const UserImpersonationInfo = ({ extendedUserData }: Props) => {
  useEffect(() => {
    const element = document.getElementById('user-impersonation')
    if (element) {
      moveJsxElement(element);
    }
  }, [])

  if (localStorage.getItem('ImpersonateCID') && localStorage.getItem('ImpersonateUID')) {
    const userFullName = localStorage.getItem('ImpersonateName')
    const logoutImpersonatedUser = () => {
      localStorage.removeItem('ImpersonateCID')
      localStorage.removeItem('ImpersonateUID')
      localStorage.removeItem('ImpersonateName')
      window.location.replace(window.location.origin)
    }

    return (
      <div id="user-impersonation" className="mt-3 d-flex justify-content-between">
        <p className='mb-0 text-truncate'>Logged in as <span className='strong'>{userFullName}</span> <span>({extendedUserData.customer})</span></p>
        <button type='button' onClick={logoutImpersonatedUser} className="btn btn-sm bg-white text-primary strong ml-3">Log out</button>
      </div>
    )
  } else return null
}